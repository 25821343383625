import React, { useState, useEffect } from 'react';
import { firebase, auth, onAuthStateChanged } from './firebase';
import StyledFirebaseAuth from './firebaseui';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Checkbox, FormControlLabel,Typography,Box } from '@mui/material';

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID, 
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      // Ensure user has accepted the terms
      if (!authResult.user.acceptedTerms) {
        alert('You must accept the terms and conditions to sign up.');
        return false; // Prevent sign-in
      }
      return true; // Allow sign-in
    },
  },
};

function FirebaseLogin() {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsDialog, setShowTermsDialog] = useState(false);
  const [showCookiePolicyDialog, setShowCookiePolicyDialog] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setTermsAccepted(event.target.checked);
  };
  const handleCheckboxClick = (event) => {
    setTermsAccepted(true);
	setCookiesAccepted(true);
  };
  // Handle opening and closing of Terms Dialog
  const handleOpenTermsDialog = () => {
    setShowTermsDialog(true);
  };

  const handleCloseTermsDialog = () => {
    setShowTermsDialog(false);
  };
const handleCloseCookiePolicyDialog = () => {
	    setShowCookiePolicyDialog(false);
}
const handleOpenCookiePolicyDialog = () => {
	    setShowCookiePolicyDialog(true);
}
  // Modify FirebaseUI config based on whether terms are accepted
  useEffect(() => {
    if (termsAccepted && cookiesAccepted) {
      uiConfig.callbacks.signInSuccessWithAuthResult = (authResult) => {
        authResult.user.acceptedTerms = true; // Simulate user accepting terms
        return true; // Allow sign-in
      };
    } else {
  uiConfig.callbacks.signInSuccessWithAuthResult = (authResult) => {
    alert('You must accept the terms and conditions & cookies to sign up.');
    firebase.auth().signOut().then(() => {
      window.location.reload(); // Refresh the current page
    });
    return false; // Prevent sign-in and default navigation behavior
  };
  
  // If the default prevention still doesn't work:
  uiConfig.callbacks.signInFailure = (error) => {
    // Handle any additional cleanup or reset here
    window.location.reload(); // Ensure the page refreshes on sign-in failure
    return Promise.resolve(); // Ensure further handling if needed
  };
}
  }, [termsAccepted,cookiesAccepted]);

  return (
    <div>
      {/* Firebase Authentication UI */}
	             <Typography
        variant="h4"
        sx={{
          mb: 3,
          color: '#ffffff',
          fontWeight: 'bold',
          letterSpacing: '0.1em', // Adds some spacing between letters
		  textAlign:'center'
        }}
      >
        Discover Our Features
      </Typography>

      <Typography
        variant="body1"
        sx={{
          mb: 4,
          color: '#cfcfcf', // Subtle color for supporting text
          lineHeight: 1.6, // Spacing for readability
        }}
      >
        Sign up or sign in to unlock all the amazing features our app has to offer and enhance your productivity.
      </Typography>
	  {!termsAccepted || !cookiesAccepted ? (
        <Typography variant="body2" color="info" sx={{ fontWeight:"bold",mb: 2 }}>
          Please accept the terms and conditions and cookie policy to proceed.
        </Typography>
      ) : null}


      {/* Terms and Conditions Checkbox */}
      <div style={{ marginTop: 20 }}>
        <FormControlLabel
          control={<Checkbox checked={termsAccepted && cookiesAccepted} onClick={handleCheckboxClick} onChange={handleCheckboxChange}   sx={{
    color: 'white',
    '&.Mui-checked': {
      color: 'white',
    },
  }} />}
          label={
            <>
              I accept the{' '}
              <Button onClick={handleOpenTermsDialog}>Terms and Conditions</Button> and{' '}
              <Button onClick={handleOpenCookiePolicyDialog}>Cookies Policy</Button>.
            </>
          }
        />
      </div>

      {/* Terms and Conditions Dialog */}
{/* Terms and Conditions Dialog */}
<Dialog open={showTermsDialog} onClose={handleCloseTermsDialog}>
  <DialogTitle>Terms and Conditions</DialogTitle>
  <DialogContent>
    <DialogContentText>
      <Typography variant="body2" gutterBottom>
        Effective Date: 20th of August 2024
      </Typography>
      <Typography variant="body1" gutterBottom>
        Welcome to EarlyBird.live ("Website"). By accessing or using our Website, you agree to comply with and be bound by the following Terms and Conditions ("Terms"). Please read these Terms carefully. If you do not agree with any part of these Terms, you must not use our Website.
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" gutterBottom>
        By accessing or using EarlyBird.live, you agree to these Terms and our Privacy Policy. These Terms may be updated or modified by us at any time without prior notice. Your continued use of the Website following any changes constitutes your acceptance of the new Terms. If you do not agree to any changes, you must discontinue using the Website.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. Eligibility
      </Typography>
      <Typography variant="body1" gutterBottom>
        To use this Website, you must be at least 18 years of age or have the consent of a parent or guardian. By using this Website, you represent and warrant that you meet these eligibility requirements.
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. User Accounts
      </Typography>
      <Typography variant="body1" gutterBottom>
        - You may be required to create an account to access certain features of the Website. You agree to provide accurate and complete information when creating an account and to update your information as necessary.
        - You are responsible for maintaining the confidentiality of your account information and password. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. Use of the Website
      </Typography>
      <Typography variant="body1" gutterBottom>
        - You agree to use the Website for lawful purposes only. You shall not post or transmit through the Website any material that violates or infringes the rights of others or is unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights, vulgar, obscene, profane, or otherwise objectionable.
      </Typography>
      <Typography variant="h6" gutterBottom>
        5. Intellectual Property Rights
      </Typography>
      <Typography variant="body1" gutterBottom>
        - All content on the Website, including but not limited to text, graphics, logos, images, videos, and software, is the property of EarlyBird or its content suppliers and is protected by international copyright laws. Unauthorized use of any content on the Website may violate copyright, trademark, and other laws.
      </Typography>
      <Typography variant="h6" gutterBottom>
        6. Links to Third-Party Websites
      </Typography>
      <Typography variant="body1" gutterBottom>
        The Website may contain links to third-party websites. These links are provided for your convenience only. EarlyBird does not endorse or make any representations about these third-party websites, and your access to any such websites is at your own risk.
      </Typography>
      <Typography variant="h6" gutterBottom>
        7. Limitation of Liability
      </Typography>
      <Typography variant="body1" gutterBottom>
        EarlyBird shall not be liable for any damages or injury resulting from your access to, or inability to access, the Website, or from your reliance on any information provided at the Website.
      </Typography>
      <Typography variant="h6" gutterBottom>
        8. Indemnification
      </Typography>
      <Typography variant="body1" gutterBottom>
        You agree to indemnify, defend, and hold harmless EarlyBird, its officers, directors, employees, agents, licensors, suppliers, and any third-party information providers from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of these Terms or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the Website using your account.
      </Typography>
      <Typography variant="h6" gutterBottom>
        9. Governing Law
      </Typography>
      <Typography variant="body1" gutterBottom>
        These Terms shall be governed by and construed in accordance with the laws of Greece. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Greece.
      </Typography>
      <Typography variant="h6" gutterBottom>
        10. Contact Information
      </Typography>
      <Typography variant="body1" gutterBottom>
        For any questions about these Terms, please contact us at info@earlybird.live.
      </Typography>
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button 
      onClick={() => {
        setTermsAccepted(true); // Set terms as accepted
        handleCloseTermsDialog(); // Close dialog
      }} 
      color="primary"
    >
      Accept
    </Button>
    <Button       onClick={() => {
        setTermsAccepted(false); // Set terms as accepted
        handleCloseTermsDialog(); // Close dialog
      }}  color="secondary">
      Decline
    </Button>
  </DialogActions>
</Dialog>
<Dialog open={showCookiePolicyDialog} onClose={handleCloseCookiePolicyDialog}>
  <DialogTitle>Cookie Policy</DialogTitle>
  <DialogContent>
    <DialogContentText>
      <Typography variant="body1" gutterBottom>
        At EarlyBird.live, we use cookies to enhance your experience on our website. This Cookie Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Website, your choices regarding cookies, and further information about cookies.
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. What Are Cookies?
      </Typography>
      <Typography variant="body1" gutterBottom>
        Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. How EarlyBird Uses Cookies
      </Typography>
      <Typography variant="body1" gutterBottom>
        When you use and access the Website, we may place a number of cookies files in your web browser. We use cookies for the following purposes:
      </Typography>
      <Typography variant="body1" gutterBottom>
        - To enable certain functions of the Website
        - To provide analytics
        - To store your preferences
        - To enable advertisements delivery, including behavioral advertising
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. Your Choices Regarding Cookies
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser:
      </Typography>
      <Typography variant="body1" gutterBottom>
        - For Chrome, visit [this page](https://support.google.com/accounts/answer/32050)
        - For Internet Explorer, visit [this page](https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer)
        - For Firefox, visit [this page](https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored)
        - For Safari, visit [this page](https://support.apple.com/en-us/HT201265)
        - For any other web browser, please visit your web browser's official web pages.
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. More Information About Cookies
      </Typography>
      <Typography variant="body1" gutterBottom>
        You can learn more about cookies and the following third-party websites:
        - [AllAboutCookies](http://www.allaboutcookies.org/)
        - [Network Advertising Initiative](http://www.networkadvertising.org/)
      </Typography>
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button 
      onClick={() => {
        setCookiesAccepted(true); // Set terms as accepted
        handleCloseCookiePolicyDialog(); // Close dialog
      }} 
      color="primary"
    >
	 Accept
    </Button>
    <Button  onClick={() => {
        setCookiesAccepted(false); // Set terms as accepted
        handleCloseCookiePolicyDialog(); // Close dialog
      }}  color="secondary">
      Decline
    </Button>
  </DialogActions>
</Dialog>
     <Box
  sx={{
    pointerEvents: termsAccepted && cookiesAccepted? 'auto' : 'none', // Disable clicks when terms are not accepted
    opacity: termsAccepted && cookiesAccepted ? 1 : 0.5, // Change opacity to indicate disabled state
  }}
>
  <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
</Box>

    </div>
  );
}

export default FirebaseLogin;
