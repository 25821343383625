import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Paper, CircularProgress,Link } from '@mui/material';
import { analytics,logEvent } from './firebase';

// Create a custom theme with the modern font
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
});

const StripePricingTable = ({ pricingTableId, publishableKey }) => {
  const [isContentStable, setIsContentStable] = useState(false); // State to manage loading status
  const [stableTime, setStableTime] = useState(0); // Time for which content has been stable

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Variable to store the previous count of elements
      let previousElementCount = document.body.getElementsByTagName("*").length;
      let stableCount = 0; // Time tracker for stability

      // Polling mechanism to check the number of elements in the body
      const interval = setInterval(() => {
        const currentElementCount = document.body.getElementsByTagName("*").length;

        if (currentElementCount === previousElementCount) {
          stableCount += 100; // Increase stable count time by 100ms intervals
        } else {
          stableCount = 0; // Reset stable count if the number of elements changes
          previousElementCount = currentElementCount; // Update element count
        }

        if (stableCount >= 3000) { // If stable for 3 seconds (3000ms)
          setIsContentStable(true); // Set state to indicate loading is complete
	      logEvent(analytics,"stripe table loaded");
          clearInterval(interval); // Clear the interval
        }
      }, 100); // Check every 100ms
    };

    // Cleanup script and interval when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="md"
        sx={{
         
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
          position: 'relative', // To position the loader correctly
        }}
      >
 <Typography
          variant="h4"
          sx={{
            color: '#afe490',
            fontWeight: 'bold',
			textAlign:'center',
			margin:"10px"
          }}
        >
          Claim Your Free Trial Today!
        </Typography>
 <Typography
      variant="h8"
      sx={{
        color: 'white',
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '10px',
      }}
    >
      Secured Payments by{' '}<br/>
      <Link
  href="https://stripe.com"
  target="_blank"
  rel="noopener noreferrer"
>
  <img 
    src="stripe.png" 
    alt="Stripe" 
    style={{
      width: '70px', // Set width of the image
      height: 'auto' // Set height to adjust automatically
    }}
  />
</Link>
    </Typography>
  
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 2,
            textAlign: 'center',
            width: '100%', 
            maxWidth: 600,
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Poppins, Arial, sans-serif',
            opacity: isContentStable ? 1 : 0, // Initially hidden, show when content is stable
            transition: 'opacity 0.5s ease', // Smooth transition when becoming visible
          }}
        >

          <Typography variant="body2" gutterBottom>
            Enjoy AI-generated proposals, instant Upwork alerts, and a free consultation with a Top Rated Plus freelancer. Subscribe now to maximize your freelancing success!
          </Typography>

          <Box sx={{ marginTop: 2, width: '100%' }}>
            <stripe-pricing-table
              pricing-table-id={pricingTableId}
              publishable-key={publishableKey}
            ></stripe-pricing-table>
          </Box>
        </Paper>

        {/* Loading spinner, shown while waiting for the content to stabilize */}
        {!isContentStable && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

export default StripePricingTable;
