import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

// Custom styles for the drawer content
const DrawerContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, #140326, #2a042c, #000000)',
  color: 'white',
  textAlign: 'left',
  borderRadius: '12px',
}));

export default function AppBarSignout({ signOut,scrollToPricingTable }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerContent = (
    <DrawerContent>
      <Typography variant="h6">Drawer Content</Typography>
      {/* Add any drawer content or navigation items here */}
    </DrawerContent>
  );

  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0)', margin: '10px' }}>
      <AppBar
        position="static"
        sx={{
          background: 'linear-gradient(90deg, rgba(33,150,243,1) 0%, rgba(156,39,176,1) 100%)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
          borderRadius: '12px',
        }}
      >
        <Toolbar>
          {/* Menu Icon */}

          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          {/* Title */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1,              cursor: 'pointer' }} onClick={scrollToPricingTable}>
            Subscribe
          </Typography>

          {/* Back Option */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              cursor: 'pointer',
              fontWeight: 'bold',
              '&:hover': {
                color: '#FFEB3B', // Hover effect for interaction
                transition: 'color 0.3s ease',
              },
            }}
            onClick={signOut} // Call signOut function when clicked
          >
            Back
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Drawer for additional navigation */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: { xs: '95%', sm: '70%', md: '50%' },
            backgroundColor: 'rgba(0,0,0,0)',
            borderRadius: '12px',
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </div>
  );
}
