import React from 'react';

const PreloadVideo = ({vdpath}) => {
  return (
    <div>
    
      {/* Other content on the first page */}

      {/* Hidden video element to preload the video for the second page */}
      <video
        preload="auto"
        style={{ display: 'none' }} // Hide the video element
      >
        <source src={vdpath} type="video/mp4" />
        {/* Add other formats if necessary */}
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default PreloadVideo;