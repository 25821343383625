import Box from '@mui/material/Box';
import React, {useRef, useEffect} from "react"

const VideoPlayer = ({src}) => {

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
	width: { xs: '100vw', md: '100%' },
        height: 'auto', 
        backgroundColor: 'transparent' 
      }}
    >
	<div
          dangerouslySetInnerHTML={{
            __html: `<video  autoplay loop muted playsinline style="width:100%;height:'auto';backgrond-color:'transparent'">
      <source src="${src}" type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
          }}
        />
     
    </Box>
  );
};

export default VideoPlayer;
