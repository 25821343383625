import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField ,Box,IconButton} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const ProjectTypesAccordion = ({
  projectTypes,
  handleAddProjectType,
  handleEditProjectType,
  handleDeleteProjectType,
  openEditDialog,
  handleCloseEditDialog,
  editedTitle,
  setEditedTitle,
  editedDescription,
  setEditedDescription,
  handleSaveEditedProjectType,
  openAddDialog,
  handleCloseAddDialog,
  newProjectTypeTitle,
  setNewProjectTypeTitle,
  newProjectTypeDescription,
  setNewProjectTypeDescription,
  handleSaveProjectType
}) => {
	const ProjectTypeItem = ({ projectType, onEdit, onDelete }) => (
  <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: '4px' }}>
    <Typography sx={{color:'white '}} variant="h6">{projectType.title}</Typography>
    <Typography sx={{color:'white '}}>{projectType.description}</Typography>
    <Box sx={{ mt: 2 }}>
      <IconButton onClick={() => onEdit(projectType)}><EditIcon sx={{ color: 'white' }} /></IconButton>
      <IconButton onClick={() => onDelete(projectType.id)}><DeleteIcon sx={{ color: 'white' }} /></IconButton>
    </Box>
  </Box>
);
  return (
    <Accordion
      sx={{
        backgroundColor: 'rgba(0,0,0,0)', // Transparent background
        backdropFilter: 'blur(10px)', // Apply blur effect
        '-webkit-backdrop-filter': 'blur(10px)', // Safari support
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} // Down arrow icon with white color
        aria-controls="panel2a-content"
        id="panel2a-header"
        sx={{ backgroundColor: 'rgba(0,0,0,0)' }} // Transparent background for summary
      >
        <Typography sx={{ color: 'white' }}>Project Types</Typography> {/* White colored title */}
      </AccordionSummary>
      
      <AccordionDetails sx={{ backgroundColor: 'rgba(0,0,0,0)' }}> {/* Transparent background for details */}
        <Typography variant="h6" sx={{ mb: 2, mt: 4, color: 'white' }}>
          Manage Project Types
        </Typography>
        <Button startIcon={<AddIcon sx={{ color: 'white' }} />} variant="contained" onClick={handleAddProjectType}>
          Add Project Type
        </Button>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {projectTypes.map((projectType) => (
            <Grid item xs={12} sm={6} md={4} key={projectType.id}>
              <ProjectTypeItem
                projectType={projectType}
                onEdit={handleEditProjectType}
                onDelete={handleDeleteProjectType}
              />
            </Grid>
          ))}
        </Grid>

        {/* Edit Project Type Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
          <DialogTitle>Edit Project Type</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="edit-title"
              label="Project Type Title"
              type="text"
              fullWidth
              variant="standard"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
            />
            <TextField
              margin="dense"
              id="edit-description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              multiline
              rows={4}
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEditDialog}>Cancel</Button>
            <Button onClick={handleSaveEditedProjectType}>Save</Button>
          </DialogActions>
        </Dialog>

        {/* Add New Project Type Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
          <DialogTitle>Add New Project Type</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Project Type Title"
              type="text"
              fullWidth
              variant="standard"
              value={newProjectTypeTitle}
              onChange={(e) => setNewProjectTypeTitle(e.target.value)}
            />
            <TextField
              margin="dense"
              id="description"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              multiline
              rows={4}
              value={newProjectTypeDescription}
              onChange={(e) => setNewProjectTypeDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDialog}>Cancel</Button>
            <Button onClick={handleSaveProjectType}>Save</Button>
          </DialogActions>
        </Dialog>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProjectTypesAccordion;
