import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics,logEvent } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyBnL4WrQ8XYe-RPxEHf0byxsJL8bpL70N0",
  authDomain: "freelancemanager-f87dd.firebaseapp.com",
  databaseURL: "https://freelancemanager-f87dd-default-rtdb.firebaseio.com",
  projectId: "freelancemanager-f87dd",
  storageBucket: "freelancemanager-f87dd.appspot.com",
  messagingSenderId: "734486233342",
  appId: "1:734486233342:web:28979b1942106dceeca5af",
  measurementId: "G-HWGSH954W4"
};

const app=firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');

export {firebase,auth,onAuthStateChanged,analytics,logEvent};
