import {React, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box,CircularProgress, TextField, Button, Stack, Snackbar, Alert,CardContent,Card,Typography,CardActions } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme,createTheme } from '@mui/material/styles';
import CryptoJS from 'crypto-js';

const UpworkLinkInput = ({
  isLoading,
  upworkProfileLink,
  setUpworkProfileLink,
  saveProfileLink,
  skipProfileLink,
  openSnackbar,
  handleCloseSnackbar,
  snackbarMessage,
  hasPaid,
  showProfileBuilding,
  showUpworkTagsInput,
  emailAddress,basicPlan
}) => {
	  const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
});
	  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Checks if screen size is 'sm' or smaller

 const navigate = useNavigate();
  const imageSrc = isMobile ? 'uwlinkMobile.png' : 'uwlink.png'; // Replace with your actual paths

  useEffect(() => {
	  console.log("inputs: "+"hasPaid: "+String(hasPaid)+"showProfileBuilding: "+String(showProfileBuilding)+"basicPlan: "+String(basicPlan))
    if (!hasPaid) {
      navigate('/payment');
    }  else if (showProfileBuilding && !basicPlan) {
      navigate('/building-profile');
    } else if (showUpworkTagsInput || basicPlan) {
		console.log("navigating to settings");
      navigate('/settings');
    } 
  }, [hasPaid, showProfileBuilding, navigate,emailAddress,basicPlan,showUpworkTagsInput]);	

	  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }	
	
  return (
    <div>
	      <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4, p: 2, textAlign: 'center' }}>
		  	  <Box
  component="img"
  src="logo.png"
  alt="Logo"
  sx={{
    m: 1,
    width: 100,
    height: "auto",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0)',
    borderRadius: '8px', // Optional: If you want rounded corners, remove if not needed
  }}
/>
 <Box
  sx={{
    display: 'flex', // Enables flexbox
    justifyContent: 'center', // Centers content horizontally
    alignItems: 'center', // Centers content vertically
    height: {
          xs: 'auto',   // Automatic height to maintain aspect ratio on small screens
          sm: '100vh',  // Full viewport height on larger screens
        },
	margin:"40px"
  }}
>
    <Box
  component="img"
      src={imageSrc}
  alt=""
  sx={{
	opacity:1,
        width: {
          xs: '100vw',  // Full width on small screens (mobile)
          sm: 'auto',   // Automatic width on larger screens
        },
        height: {
          xs: 'auto',   // Automatic height to maintain aspect ratio on small screens
          sm: '100vh',  // Full viewport height on larger screens
        },
    mt: 2, // Adds some margin-top to separate the image from the text above
				   borderRadius: '16px', // Rounded corners for a modern look
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background for glassmorphism effect
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.5)', // Soft shadow for depth
    backdropFilter: 'blur(10px)', // Blur effect for a frosted glass look
    border: '1px solid rgba(255, 255, 255, 0.2)', // Light border for subtle definition
    transition: 'all 0.3s ease', // Smooth transition for hover effects
    '&:hover': {
      boxShadow: '0 6px 50px rgba(0, 0, 0, 0.7)', // More intense shadow on hover
    }
  }}
/>
</Box>

    </Box>
      <TextField
        label="Enter your Upwork profile link"
        variant="outlined"
        value={upworkProfileLink}
        onChange={(e) => setUpworkProfileLink(e.target.value)}
        fullWidth
        margin="normal"
					  sx={{
    input: { color: 'white' }, // Change the input text color to white
    label: { color: 'white' }, // Change the label text color to white
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Change the border color to white
      },
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color to white when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color to white when focused
      },
    },
  }}
      />
	

  
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={saveProfileLink}>Submit</Button>
        <Button variant="contained" color="primary" onClick={skipProfileLink}>Skip</Button>
      </Stack>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UpworkLinkInput;
