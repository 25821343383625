import {React, useEffect,useState,useRef} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import StripePricingTable from './StripePricingTable';
import {  Snackbar, Alert,Typography, Box } from '@mui/material';
import Reviews from './Reviews';
import FreelancerSuccess from './FreelancerSuccess';
import VideoPlayer from './VideoPlayer';
import CryptoJS from 'crypto-js';
import AppBarSignout from './AppBarSignout';
import useNoPendingRequests from './useNoPendingRequests'

const PaymentPage = ( { onPaymentVerification,hasPaid,snackbarMessage, openSnackbar,handleCloseSnackbar,emailAddress,initEmail,setInitEmail,basicPlan,signOut }) => {
	const location = useLocation(); 
	const navigate = useNavigate();
  const pricingTableRef = useRef(null);
    const topRef = useRef(null);

  const [resourcesReady, setResourcesReady] = useState(false);
  const resourcesLoaded = useNoPendingRequests(resourcesReady);
  const [scrolled, setScrolled] = useState(false);
 const scrollTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  useEffect(() => {

  if (!scrolled) {
	  	     scrollTop();
			  setScrolled(true);
		}
    // Simulate fetching or loading resources dynamically
    setTimeout(() => {
		
      console.log('Resources are returned, now starting to monitor network requests.');
      setResourcesReady(true); // Start monitoring after resources are added to the page
    }, 2000); // Simulate a delay in loading resources
  }, []);
    // This is where you could check the payment status
	   useEffect(() => {
		   console.log(hasPaid);
		   if (!hasPaid) {
	 const params = new URLSearchParams(location.search);
  let sessionId= params.get('sessionID');
  console.log(sessionId);
  if (sessionId) {
    let verified=onPaymentVerification(sessionId);	
			    if (emailAddress!='' && emailAddress!=null && initEmail=='') {
      const hashedEmail = CryptoJS.SHA256(emailAddress).toString(CryptoJS.enc.Hex);
	  	  setInitEmail(hashedEmail);

      window.ttq.identify({
        email: hashedEmail
      });
	  
	  window.ttq.track('CompletePayment', {
	"contents": [
		{
			"content_id": "1", // string. ID of the product. Example: "1077218".
			"content_type": "product", // string. Either product or product_group.
			"content_name": "subscription" // string. The name of the page or product. Example: "shirt".
		}
	],
	"value": "1", // number. Value of the order or items sold. Example: 100.
	"currency": "USD" // string. The 4217 currency code. Example: "USD".
});
    }
  }	
		   } else if (!basicPlan){
			   navigate('/upwork-link');
		   } else {
			    navigate('/settings');
		   }
	   }, [navigate,location,hasPaid,basicPlan,emailAddress])
 const scrollToPricingTable = () => {
    if (pricingTableRef.current) {
      pricingTableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

   useEffect(() => {
	    const params2 = new URLSearchParams(location.search);
  let sessionId2= params2.get('sessionID');
    if (emailAddress!='' && emailAddress!=null && initEmail=='' && !sessionId2) {
      const hashedEmail = CryptoJS.SHA256(emailAddress).toString(CryptoJS.enc.Hex);
	  setInitEmail(hashedEmail);
      window.ttq.identify({
        email: hashedEmail
      });
	  console.log("send");
	  console.log(initEmail);
	  window.ttq.track('AddToCart', {
	"contents": [
		{
			"content_id": "1", // string. ID of the product. Example: "1077218".
			"content_type": "product", // string. Either product or product_group.
			"content_name": "subscription" // string. The name of the page or product. Example: "shirt".
		}
	],
	"value": "1", // number. Value of the order or items sold. Example: 100.
	"currency": "USD" // string. The 4217 currency code. Example: "USD".
});
    }
  }, [emailAddress]);



 return (
    <div style={{opacity:(resourcesReady?1:0),transition: 'opacity 1s ease-in'}}>
      <Box ref={topRef}
        sx={{
          textAlign: 'center',
          mb: 4,
          p: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)', // Frosted glass effect for futuristic look
          borderRadius: '12px'
        }}
      >
	  <Box 
  component="img"
  src="logo.png"
  alt="Logo"
  sx={{
    m: 1,
    width: 100,
    height: "auto",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0)',
    borderRadius: '8px', // Optional: If you want rounded corners, remove if not needed
  }}
/>
 </Box>
 <AppBarSignout signOut={signOut} scrollToPricingTable={scrollToPricingTable}/>
  <VideoPlayer src={"freetrial.m4v"}/>


	<Reviews />
	      <Box ref={pricingTableRef}
        sx={{
          textAlign: 'center',
          mb: 4,
          p: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)', // Frosted glass effect for futuristic look
          borderRadius: '12px',
        }}
      >
      <StripePricingTable
        pricingTableId="prctbl_1PqZP6HZmSrxSYbSX2kCQhxM"
        publishableKey="pk_live_51HOpmiHZmSrxSYbSIA29V4JTkzHFbMemFrqUfLSvA44Nd2Nforb2TVEye0kaNZPdahbQiYTGABV693dGnZbSACFu00C72CeytA"
      />
	  </Box>
	        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
	 
    </div>
  )

};

export default PaymentPage;
