import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BudgetSelector = ({ fixedPriceRange, setFixedPriceRange, hourlyRateRange, setHourlyRateRange }) => {
  return (
    <Accordion
      sx={{
        backgroundColor: 'rgba(0,0,0,0)', // Transparent background for accordion
        backdropFilter: 'blur(10px)', // Apply blur effect
        '-webkit-backdrop-filter': 'blur(10px)', // Safari support for blur effect
      }}
    >
      {/* AccordionSummary contains the title "Budget" and the down arrow icon */}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} // Down arrow icon with white color
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ backgroundColor: 'rgba(0,0,0,0)' }} // Transparent background for summary
      >
        <Typography sx={{ color: 'white' }}>Budget *</Typography> {/* White colored title */}
      </AccordionSummary>
      
      {/* AccordionDetails contains the TextField components, which are hidden until expanded */}
      <AccordionDetails sx={{ backgroundColor: 'rgba(0,0,0,0)' }}> {/* Transparent background for details */}
        <TextField
          sx={{
            input: { color: 'white' }, // Change the input text color to white
            label: { color: 'white' }, // Change the label text color to white
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', // Change the border color to white
              },
              '&:hover fieldset': {
                borderColor: 'white', // Change the border color to white when hovered
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Change the border color to white when focused
              },
            },
          }}
          label="Fixed Budget Range"
          variant="outlined"
          value={fixedPriceRange}
          onChange={(e) => {
            if (/^\d*$/.test(e.target.value)) {
              setFixedPriceRange(e.target.value);
            }
          }}
          fullWidth
          margin="normal"
        />

        <TextField
          sx={{
            input: { color: 'white' }, // Change the input text color to white
            label: { color: 'white' }, // Change the label text color to white
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', // Change the border color to white
              },
              '&:hover fieldset': {
                borderColor: 'white', // Change the border color to white when hovered
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Change the border color to white when focused
              },
            },
          }}
          label="Hourly Rate Range"
          variant="outlined"
          value={hourlyRateRange}
          onChange={(e) => {
            if (/^\d*$/.test(e.target.value)) {
              setHourlyRateRange(e.target.value);
            }
          }}
          fullWidth
          margin="normal"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default BudgetSelector;
