// SettingsPage.js
import React, {useEffect,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress,Box,Snackbar, TextField, Button, Stack, Autocomplete,Alert, Chip, Typography } from '@mui/material';
import { Tooltip, InputAdornment,Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,IconButton,Grid } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import CustomPromptManager from './CustomPromptManager';
import SlackButton from './SlackButton';
import SlackDisconnectButton from './SlackDisconnectButton';
import TelegramButton from './TelegramButton';
import TelegramDisconnectButton from './TelegramDisconnectButton';
import TagSelector from './TagSelector'; // Import the TagSelector component
import BudgetSelector from './BudgetSelector'; // Import the BudgetSelector component
import ProjectTypesAccordion from './ProjectTypesAccordion'; // Import the ProjectTypesAccordion component

const SettingsPage = ({
   projectTypes,
   setProjectTypes,
  selectedTags,
  setSelectedTags,
  fixedPriceRange,
  setFixedPriceRange,
  hourlyRateRange,
  setHourlyRateRange,
  tagOptions,
  saveTags,
  isLoading,
  openSnackbar,
handleCloseSnackbar,
snackbarMessage,
showUpworkLinkInput, hasPaid, showProfileBuilding, openAIKeySetup,customPrompt,setCustomPrompt,model,setModel,allowRefresh,skipped,basicPlan,infoLoaded,userID,hasSlack,slackAccessToken,emailAlerts,setEmailAlerts,hasTelegram
}) => {
console.log(skipped);
  // Assume tagOptions is passed as a prop and contains the available tags
  const [deactivateAccount, setDeactivateAccount] = useState(false);
const [openDialog, setOpenDialog] = useState(false);

const [openAddDialog, setOpenAddDialog] = useState(false);
const [newProjectTypeTitle, setNewProjectTypeTitle] = useState('');
const [newProjectTypeDescription, setNewProjectTypeDescription] = useState('');

const [openEditDialog, setOpenEditDialog] = useState(false);
const [editingProjectType, setEditingProjectType] = useState(null);
const [editedTitle, setEditedTitle] = useState('');
const [editedDescription, setEditedDescription] = useState('');
const [openAIKey, setOpenAIKey] = useState('');
const [profileUrl,setProfileUrl]= useState('');
const [deleteApiKey,setDeleteApiKey]=useState(false);
const [refreshProfile,setRefreshProfile]=useState(false);
const [emailAlertsCheckboxToggled,setEmailAlertsCheckboxToggled]=useState((emailAlerts!="No"?true:false));
const handleAddProjectType = () => {
  setOpenAddDialog(true);
};


const handleCloseAddDialog = () => {
  setOpenAddDialog(false);
  setNewProjectTypeTitle('');
  setNewProjectTypeDescription('');
};
  const handleDeleteApiKeyChange = (event) => {
    setDeleteApiKey(event.target.checked);
    if (event.target.checked) {
      setOpenAIKey(''); // Clear the API key field when the checkbox is checked
    }
  };
  const handleRefreshProfileChange = (event) => {
    setRefreshProfile(event.target.checked);

  };
const handleSaveProjectType = () => {
  // Here you would typically generate a unique ID for the new project type
  // For simplicity, we're using a timestamp. In a real application, ensure you're using a proper ID.
  const newProjectType = {
    id: Date.now(), // Simple unique ID generation
    title: newProjectTypeTitle,
    description: newProjectTypeDescription,
  };
  setProjectTypes([...projectTypes, newProjectType]);
  handleCloseAddDialog(); // Reset and close the dialog
};

const ProjectTypeItem = ({ projectType, onEdit, onDelete }) => (
  <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: '4px' }}>
    <Typography variant="h6">{projectType.title}</Typography>
    <Typography>{projectType.description}</Typography>
    <Box sx={{ mt: 2 }}>
      <IconButton onClick={() => onEdit(projectType)}><EditIcon sx={{ color: 'white' }} /></IconButton>
      <IconButton onClick={() => onDelete(projectType.id)}><DeleteIcon sx={{ color: 'white' }} /></IconButton>
    </Box>
  </Box>
);



 // const handleEditProjectType = (projectType) => {
  //  setProjectTypes(projectTypes.map(function(v){if (v.id==projectType.id){return projectType;} else {return v;}}));
  //};
const handleEditProjectType = (projectType) => {
  setEditingProjectType(projectType);
  setEditedTitle(projectType.title);
  setEditedDescription(projectType.description);
  setOpenEditDialog(true);
};
const handleCloseEditDialog = () => {
  setOpenEditDialog(false);
  setEditingProjectType(null);
  setEditedTitle('');
  setEditedDescription('');
};

const handleSaveEditedProjectType = () => {
  const updatedProjectTypes = projectTypes.map((pt) => {
    if (pt.id === editingProjectType.id) {
      return { ...pt, title: editedTitle, description: editedDescription };
    }
    return pt;
  });
  setProjectTypes(updatedProjectTypes);
  handleCloseEditDialog();
};
  const handleDeleteProjectType = (projectId) => {
    // Logic to delete a project type (confirmation dialog, then update state)
    setProjectTypes(projectTypes.filter(pt => pt.id !== projectId));
  };

const handleCheckboxChange = (event) => {
	  const isToggled = event.target.checked;

  if (isToggled) {
	setDeactivateAccount(true);
    setOpenDialog(true);
  } else {
    setDeactivateAccount(false);
  }
};

const handleEmailAlertsCheckboxChange = (event) => {
	  const isToggled = event.target.checked;

  if (isToggled) {
	setEmailAlerts("Yes");
	setEmailAlertsCheckboxToggled(true);
	console.log("set yes");
  } else {
    setEmailAlerts("No");
	setEmailAlertsCheckboxToggled(false);

  }
};

const handleDialogClose = (agree) => {
  setOpenDialog(false);
  if (agree) {
    // Logic to deactivate the account
    console.log("Account deactivation process here.");
  } else {
    setDeactivateAccount(false);
  }
};

  	  const navigate = useNavigate();

  useEffect(() => {
    if (showUpworkLinkInput && !basicPlan) {
      navigate('/upwork-link');
    } else if (!hasPaid && infoLoaded) {
      navigate('/payment');
    }  else if (showProfileBuilding && !basicPlan) {
      navigate('/building-profile');
    }
	
    // Add other conditions as needed
  }, [showUpworkLinkInput, hasPaid, showProfileBuilding, navigate]);
  
	  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Settings
      </Typography>
      <TagSelector
        tagOptions={tagOptions}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
expanded={false}
		titleHidden={false}

      />

 <BudgetSelector
        fixedPriceRange={fixedPriceRange}
        setFixedPriceRange={setFixedPriceRange}
        hourlyRateRange={hourlyRateRange}
        setHourlyRateRange={setHourlyRateRange}
      />
{!basicPlan && skipped && (
           <TextField
		   		sx={{
    input: { color: 'white' }, // Change the input text color to white
    label: { color: 'white' }, // Change the label text color to white
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Change the border color to white
      },
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color to white when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color to white when focused
      },
    },
  }}
        label="Add your upwork profile url"
        variant="outlined"
        value={profileUrl}
        onChange={(e) => setProfileUrl(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="If you provide your profile we can automatically sync your portfolio from upwork and have it available for adding references in your proposal.">
                <IconButton>
                  <InfoIcon sx={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
)}
{!basicPlan && <ProjectTypesAccordion
        projectTypes={projectTypes}
        handleAddProjectType={handleAddProjectType}
        handleEditProjectType={handleEditProjectType}
        handleDeleteProjectType={handleDeleteProjectType}
        openEditDialog={openEditDialog}
        handleCloseEditDialog={handleCloseEditDialog}
        editedTitle={editedTitle}
        setEditedTitle={setEditedTitle}
        editedDescription={editedDescription}
        setEditedDescription={setEditedDescription}
        handleSaveEditedProjectType={handleSaveEditedProjectType}
        openAddDialog={openAddDialog}
        handleCloseAddDialog={handleCloseAddDialog}
        newProjectTypeTitle={newProjectTypeTitle}
        setNewProjectTypeTitle={setNewProjectTypeTitle}
        newProjectTypeDescription={newProjectTypeDescription}
        setNewProjectTypeDescription={setNewProjectTypeDescription}
        handleSaveProjectType={handleSaveProjectType}
      />
}
{!basicPlan &&
      <CustomPromptManager
        customPrompt={customPrompt}
        setCustomPrompt={setCustomPrompt}
		model={model}
		setModel={setModel}
      />
}
{!basicPlan && !openAIKeySetup && (
           <TextField
		  sx={{
    input: { color: 'white' }, // Change the input text color to white
    label: { color: 'white' }, // Change the label text color to white
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Change the border color to white
      },
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color to white when hovered
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color to white when focused
      },
    },
  }}
        label="OpenAI Key"
        variant="outlined"
        type="password"
        value={openAIKey}
        onChange={(e) => setOpenAIKey(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Your key will be encrypted and securely stored in our database, ensuring protection against unauthorized access. Once saved, it will no longer be retrievable from this page for added security. However, you will have the option to delete it if needed. Additionally, upon storing your key, you will be automatically signed out for security purposes. Simply log in again to continue.">
                <IconButton>
                  <InfoIcon sx={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
)}
{!basicPlan && <FormControlLabel
        control={
<Checkbox
  checked={deleteApiKey}
  onChange={handleDeleteApiKeyChange}
  color="default" // Use default color to apply custom styles
  sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }}
/>
        }
        label="Delete my OpenAI key"
      />
}
      {(allowRefresh && !skipped && !basicPlan) && (<FormControlLabel
        control={
          <Checkbox
            checked={refreshProfile}
            onChange={handleRefreshProfileChange}
             color="default" // Use default color to apply custom styles
  sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }}
          />
        }
        label="Refresh my profolio"
      />)}
<FormControlLabel
  control={
    <Checkbox
	 sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }}
	checked={emailAlertsCheckboxToggled}
      onChange={handleEmailAlertsCheckboxChange}
      name="emailAlertsCheckboxToggled"
    />
  }
  label="Email Alerts?"
/>
<FormControlLabel
  control={
    <Checkbox
	 sx={{
    color: 'white', // Unchecked color
    '&.Mui-checked': {
      color: 'white', // Checked color
    },
  }}
	checked={deactivateAccount}
      onChange={handleCheckboxChange}
      name="deactivateAccount"
    />
  }
  label="Deactivate Account?"
/>
{!hasSlack &&
	  <SlackButton userID={userID}/>
}
{hasSlack &&
	  <SlackDisconnectButton userID={userID} slackAccessToken={slackAccessToken}/>
}
{!hasTelegram &&
	  <TelegramButton userID={userID}/>
}
{hasTelegram &&
	  <TelegramDisconnectButton userID={userID}/>
}
<Dialog
  open={openDialog}
  onClose={() => handleDialogClose(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Deactivate Account?"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Are you sure that you want to deactivate your account?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => handleDialogClose(false)}>No</Button>
    <Button onClick={() => handleDialogClose(true)} autoFocus>
      Yes
    </Button>
  </DialogActions>
</Dialog>

      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={() => saveTags(deactivateAccount,deleteApiKey,openAIKey,refreshProfile,profileUrl,emailAlerts)}>
          Save Settings
        </Button>
      </Stack>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar}   severity={snackbarMessage.includes('Failed') || snackbarMessage.includes('Error') ? 'error' : 'success'}  sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SettingsPage;

