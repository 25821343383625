import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const reviews = [
  {
    title: 'A Game Changer!',
    content: `I've been using Early Bird for 1.5 years now, and it has truly been a life changer! From the very first week, I could see how effective and useful this app is. I receive a ton of new project opportunities every day, and it saves me countless hours of searching for new jobs. Early Bird has all the tools you need to create a unique proposal for each client. It covers the entire process seamlessly, from notifying you of new projects every 5 minutes to generating and sending proposals along with references to clients. I initially started using it part time to earn some extra income, but it quickly became my main source of income. In my first year using Early Bird, I earned $50k on Upwork alone, all thanks to this incredible app. I cannot recommend it enough. The only thing you need to do is give it a try, and I guarantee you'll be hooked!`,
    author: 'Filippos T',
    position: 'Senior Video Editor',
    source: 'EarlyBird - 8/25/2024',
    link: 'https://www.producthunt.com/posts/earlybird-3?comment=3820430',
  },
  {
    title: 'This is such a useful tool! 🎉',
    content: `@early_bird, your approach to getting Upwork job alerts and crafting proposals is spot on. It’s crazy how much time we can save using automation. The instant alerts make it way easier to jump on opportunities before they’re gone, and tailored proposals definitely improve our response rate. I’ve been looking for ways to streamline my workflow, and this sounds like a perfect solution. Definitely giving it a try!`,
    author: 'Elke',
    position: 'indie hacker Web, Chrome, IOS app',
    link: 'https://www.producthunt.com/posts/earlybird-3?comment=3818710',
  },
  {
    title: 'Must-have tool for freelancers on Upwork!',
    content: `This sounds like a must-have tool for freelancers on Upwork! I can’t believe how much time I waste on this stuff. Thanks for sharing!`,
    author: 'Benjamin L',
    position: 'Igniting AI Innovation',
    link: 'https://www.producthunt.com/posts/earlybird-3?comment=3818875',
  },
  {
    title: 'Super handy tool for freelancers',
    content: `EarlyBird sounds like a super handy tool for freelancers. Just took a quick look, and it seems like a great way to save time and land more gigs. Congrats on the launch!`,
    author: 'David C',
    position: 'Empowering Your Digital Frontier.',
    link: 'https://www.producthunt.com/posts/earlybird-3?comment=3818488',
  },
];

const Reviews = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 5000); // Automatically change slide every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  const handlePrevious = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
  <div>
  			        <Typography
          variant="h4"
          sx={{
            color: '#afe490',
            fontWeight: 'bold',
			textAlign:'center',
			margin:"10px"
          }}
        >
          Our Reviews
        </Typography>
	    <Box
  component="img"
  src="REVIEWS.png" // Replace with the path to your image
  alt=""
  sx={{
	opacity:1,
    width: 'auto', // Ensures the image takes the full width of its container
    height: '200px', // Maintains the aspect ratio of the image
    mt: 2, // Adds some margin-top to separate the image from the text above
				   borderRadius: '16px', // Rounded corners for a modern look
    backgroundColor: 'rgba(255, 255, 255, 0)', // Semi-transparent background for glassmorphism effect
	        margin: '0 auto',
        position: 'relative',
		          display: 'flex'
  }}
/>
	
    <Box
      sx={{
        width: '80%',
        maxWidth: '400px',
        margin: '0 auto',
        position: 'relative',
        padding: { xs: '8px', sm: '16px' }, // Responsive padding for different screen sizes
        background: 'linear-gradient(135deg, #f5f7fa, #e4ebf0)',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        borderRadius: 4,
        overflow: 'hidden',
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.03)',
        },
      }}
    >


      <Paper
        elevation={3}
        sx={{
          padding: { xs: 1, sm: 2 }, // Smaller padding on mobile
          height: '200px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: '#ffffff',
          borderRadius: 2,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Grid container spacing={1} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '0.85rem', sm: '1rem' }, fontWeight: 'bold', color: '#333' }}>
              {reviews[currentReviewIndex].title}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ overflow: 'hidden' }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: '0.75rem', sm: '0.875rem' }, // Smaller font size on mobile
                maxHeight: '100px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#555',
                WebkitLineClamp: 3,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {reviews[currentReviewIndex].content}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', sm: '0.75rem' }, fontStyle: 'italic', color: '#666' }}>
              {reviews[currentReviewIndex].author}, {reviews[currentReviewIndex].position}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" display="block" sx={{ fontSize: { xs: '0.65rem', sm: '0.7rem' }, mb: 1, color: '#888' }}>
              {reviews[currentReviewIndex].source}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="a"
              href={reviews[currentReviewIndex].link}
              target="_blank"
              rel="noopener noreferrer"
              variant="caption"
              color="primary"
              sx={{
                textDecoration: 'none',
                fontSize: { xs: '0.7rem', sm: '0.8rem' }, // Smaller font size on mobile
                color: '#1976d2',
                fontWeight: 'bold',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Read more on Product Hunt
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Navigation Buttons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'absolute',
          top: '50%',
          width: '100%',
          transform: 'translateY(-50%)',
          px: 1,
        }}
      >
        <IconButton size="small" onClick={handlePrevious} sx={{ color: '#1976d2' }}>
          <ArrowBack fontSize="small" />
        </IconButton>
        <IconButton size="small" onClick={handleNext} sx={{ color: '#1976d2' }}>
          <ArrowForward fontSize="small" />
        </IconButton>
      </Box>
    </Box>
	</div>
  );
};

export default Reviews;
