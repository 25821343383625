import React, { useState } from 'react';

const generateState = async (userID) => {
  const random = Math.floor(Math.random() * 10e10).toString();

  // Convert the random value into an ArrayBuffer
  const encoder = new TextEncoder();
  console.log(process.env.REACT_APP_API_KEY);
  const data = encoder.encode(random + process.env.REACT_APP_API_KEY);

  // Use SubtleCrypto to generate the SHA-256 hash
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);

  // Convert the ArrayBuffer to base64 or hex
const hashBase64 = btoa(String.fromCharCode(...new Uint8Array(hashBuffer)));
return `${random}.${userID}.${hashBase64}`
};

const SlackButton = ({userID}) => {
	const [clicked,setClicked]=useState(false);
  const handleAddToSlack = async () => {
    const encodedState = encodeURIComponent(await generateState(userID));
    const slackOAuthUrl = `https://slack.com/oauth/v2/authorize?scope=channels%3Ajoin%2Cchat%3Awrite%2Cincoming-webhook&user_scope=&redirect_uri=https%3A%2F%2Fus-central1-upwork-projects-415513.cloudfunctions.net%2Fslack_chatbot%2Foauth&client_id=7745715837905.7735585527188&state=${encodedState}&userId=${userID}`;
    setClicked(true);
		window.location.href = slackOAuthUrl;

   // window.open(slackOAuthUrl, '_blank');
  };

  return (
  <div>
  {!clicked && <button
      style={{
        alignItems: 'center',
        color: '#fff',
        backgroundColor: '#4A154B',
        border: 0,
        borderRadius: '56px',
        display: 'inline-flex',
        fontWeight: 600,
        justifyContent: 'center',
        textDecoration: 'none',
        width: '100%',
        cursor: 'pointer',
		whiteSpace:'nowrap'
      }}
      onClick={handleAddToSlack}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: '24px', width: '24px', marginRight: '12px' }}
        viewBox="0 0 122.8 122.8"
      >
        <path
          d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
          fill="#e01e5a"
        ></path>
        <path
          d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
          fill="#36c5f0"
        ></path>
        <path
          d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
          fill="#2eb67d"
        ></path>
        <path
          d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
          fill="#ecb22e"
        ></path>
      </svg>
      Connect Slack
    </button>
  }
  </div>);
};

export default SlackButton;
