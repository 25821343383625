import {React, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress,Box, Container, Grid, Card, CardContent, TextField, IconButton, CardActions, Button, Snackbar, Alert, Fab } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveIcon from '@mui/icons-material/Save';

import InfoButton from './InfoButton';
const YourPortfolio = ({
  data,
  isMobile,
  toggleFavorite,
  updateField,
  addNewItem,
  deleteItem,
  getFullLink,
  saveProfiles,
  snackbarMessage,
  openSnackbar,
  handleCloseSnackbar,
  isLoading,
    showUpworkLinkInput,
  hasPaid,
  showProfileBuilding,
  showUpworkTagsInput,basicPlan,infoLoaded
}) => {
	
	
	 const navigate = useNavigate();

  useEffect(() => {
    if (showUpworkLinkInput) {
      navigate('/upwork-link');
    } else if (!hasPaid && infoLoaded) {
      navigate('/payment');
    }  else if (showProfileBuilding) {
      navigate('/building-profile');
    } else if (showUpworkTagsInput) {
      navigate('/settings');
    } else if (basicPlan) {
	      navigate('/settings');	
	}
    // Add other conditions as needed
  }, [showUpworkLinkInput, hasPaid, showProfileBuilding, navigate,basicPlan]);

	
	  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      {/* Info Button Component - Assuming this is a custom component */}
      <InfoButton content="Welcome to your portfolio dashboard! This space allows you to seamlessly manage your projects. Here, you have the flexibility to:

- Edit project details or links as you see fit.
- Add new projects to showcase your evolving portfolio.
- Remove any projects that no longer represent your best work.
- Highlight projects as favorites, prioritizing them in proposals where relevant.

For instance, you can substitute a job listing link with one that directly showcases the project's deliverables, tailoring your portfolio to highlight your achievements effectively.">
      </InfoButton>
            <Button sx={{marginBottom:"20px"}} startIcon={<AddCircleOutlineIcon />} onClick={addNewItem} variant="contained">
              Add New Item
            </Button>

      <Container maxWidth="md">
        <Grid 
          container 
          spacing={2} 
          sx={{ 
            height: '100vh',  // Full viewport height
            overflowY: 'auto' // Make the grid container scrollable
          }}
        >
          {data.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Card variant="outlined" sx={{ mb: 2, backgroundColor: 'rgba(0,0,0,0)' }}>
                <CardContent sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                  <TextField
                    fullWidth
                    label="Summary"
                    multiline
                    rows={isMobile ? 1 : 3}
                    variant="outlined"
                    value={item.summary}
                    onChange={(e) => updateField(index, 'summary', e.target.value)}
                    sx={{
                      textarea: { color: 'white' }, // Fix property to textarea
                      input: { color: 'white' },
                      label: { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                      },
                      mb: 2,
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Link"
                    variant="outlined"
                    value={getFullLink(item.id)}
                    onChange={(e) => updateField(index, 'id', e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton component="a" href={getFullLink(item.id)} target="_blank">
                          <OpenInNewIcon />
                        </IconButton>
                      ),
                    }}
                    sx={{
                      input: { color: 'white' },
                      label: { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                      },
                      mb: 2,
                    }}
                  />
                </CardContent>
                <CardActions>
                  <IconButton aria-label="add to favorites" onClick={() => toggleFavorite(index)} sx={{ color:'white',mr: 1 }}>
                    {item.favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  </IconButton>
                  <IconButton color="error" onClick={() => deleteItem(index)}>
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Floating Action Button for Saving */}
      <Fab 
        color="primary" 
        aria-label="save" 
        onClick={saveProfiles}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1
        }}
      >
        <SaveIcon />
      </Fab>

      {/* Snackbar for Notifications */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarMessage.includes('Failed') ? 'error' : 'success'} 
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
 )
};

export default YourPortfolio;
