import { useEffect, useState, useRef } from 'react';

const useNoPendingRequests = (startChecking) => {
  const [resourcesLoaded, setResourcesLoaded] = useState(false);
  const noNewRequestTimeout = useRef(null);

  useEffect(() => {
    if (!startChecking) return; // Wait until the condition to start checking is met

    const checkForPendingRequests = () => {
      const resources = window.performance.getEntriesByType('resource');
      const now = performance.now();

      // Filter requests that have finished in the last 2 seconds
      const recentRequests = resources.filter((resource) => {
        const timeSinceEnd = now - resource.responseEnd;
        return timeSinceEnd < 2000; // Check requests that finished in the last 2 seconds
      });

      return recentRequests.length === 0; // If no recent requests, we consider no pending requests
    };

    const startMonitoring = () => {
      const checkAndSetLoaded = () => {
        if (checkForPendingRequests()) {
          if (noNewRequestTimeout.current) clearTimeout(noNewRequestTimeout.current);

          // Set a 2-second timeout if no new requests have been made
          noNewRequestTimeout.current = setTimeout(() => {
            setResourcesLoaded(true); // No new requests for 2 seconds, mark as loaded
            console.log('No new requests for 2 seconds, resources are fully loaded.');
          }, 2000);
        } else {
          if (noNewRequestTimeout.current) clearTimeout(noNewRequestTimeout.current);
        }
      };

      // Monitor resource loading every 500ms
      const intervalId = setInterval(checkAndSetLoaded, 500);

      return () => {
        clearInterval(intervalId); // Clean up the interval on unmount
        if (noNewRequestTimeout.current) clearTimeout(noNewRequestTimeout.current);
      };
    };

    // Start monitoring when the condition is met (startChecking === true)
    startMonitoring();
  }, [startChecking]); // The effect depends on startChecking

  return resourcesLoaded;
};

export default useNoPendingRequests;
