import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails,Typography, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomPromptManager = ({ customPrompt, setCustomPrompt, model, setModel }) => {
  const [openCustomPromptDialog, setOpenCustomPromptDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [selectedModel, setSelectedModel] = useState(model || '');

  useEffect(() => {
    if (customPrompt) {
      setCurrentPrompt(customPrompt);
    }
    if (model) {
      setSelectedModel(model);
    }
  }, [customPrompt, model]);

  const handleOpenCustomPromptDialog = () => {
    setCurrentPrompt('');
    setSelectedModel('');
    setIsEditing(false);
    setOpenCustomPromptDialog(true);
  };

  const handleCloseCustomPromptDialog = () => setOpenCustomPromptDialog(false);

  const handleSaveCustomPrompt = () => {
    setCustomPrompt(currentPrompt);
    setModel(selectedModel);
    handleCloseCustomPromptDialog();
  };

  const handleEditCustomPrompt = () => {
    setIsEditing(true);
    setOpenCustomPromptDialog(true);
  };

  const handleDeleteCustomPrompt = () => {
    setCustomPrompt('');
    setCurrentPrompt('');
    setSelectedModel('');
	setModel('');
  };

  return (
    <div>
    <Accordion
      sx={{
        backgroundColor: 'rgba(0,0,0,0)', // Transparent background
        backdropFilter: 'blur(10px)', // Apply blur effect
        '-webkit-backdrop-filter': 'blur(10px)', // Safari support
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} // Down arrow icon with white color
        aria-controls="panel2a-content"
        id="panel2a-header"
        sx={{ backgroundColor: 'rgba(0,0,0,0)' }} // Transparent background for summary
      >
        <Typography sx={{ color: 'white' }}>Custom Prompt Manager</Typography> {/* White colored title */}
      </AccordionSummary>
      
      <AccordionDetails sx={{ backgroundColor: 'rgba(0,0,0,0)' }}> {/* Transparent background for details */}
      <Typography variant="body2" style={{ color:'white',whiteSpace: 'pre-line', fontSize: '0.875rem' }}>
        Not satisfied with the AI generated proposals? Write your own custom prompt using the following variables:
        <br />
        1) #PORTFOLIO#: This will reference your entire portfolio or only the projects that you selected.
        <br />
        2) #MYAPPROACH#: This will incorporate your personal approach if you provide one during the proposal generation along with the selected project type text (if you selected any)
        <br />
        <br />
        Example Prompt:
        <br />
      </Typography>
      <Typography variant="body2" style={{ color:'white', whiteSpace: 'pre-line', fontSize: '0.875rem', fontStyle: 'italic' }}>
        I am an Upwork freelancer and I want to get this project.
        <br />
        Write my proposal in two paragraphs and select 2 relevant examples from my portfolio:
        <br />#PORTFOLIO#.
        <br />
        Also include make sure to include the following part
        <br />#MYAPPROACH#
        <br />
        <br />
      </Typography>

      {!customPrompt && (
        <Button variant="outlined" onClick={handleOpenCustomPromptDialog}>
          Write your own Prompt
        </Button>
      )}
      {customPrompt && (
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Typography sx={{ color:'white',whiteSpace: 'pre-line', fontSize: '0.875rem', fontStyle: 'italic' }}>
                  {customPrompt}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={handleEditCustomPrompt}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" onClick={handleDeleteCustomPrompt}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
      <Dialog
        open={openCustomPromptDialog}
        onClose={handleCloseCustomPromptDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{isEditing ? 'Edit Your Prompt' : 'Create Your Own Prompt'}</DialogTitle>
        <DialogContent>
		          <FormControl fullWidth margin="dense" variant="outlined" sx={{ mt: 2 }}>
            <InputLabel id="select-model-label" sx={{ mt: -1 }}>Model</InputLabel>
            <Select
              labelId="select-model-label"
              id="select-model"
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
            >
			  <MenuItem value="o1-preview">o1-preview</MenuItem>
              <MenuItem value="gpt-4o">gpt-4o</MenuItem>
              <MenuItem value="gpt-4-turbo	">gpt-4-turbo</MenuItem>
              <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="custom-prompt"
            label="Custom Prompt"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={10}
            value={currentPrompt}
            onChange={(e) => setCurrentPrompt(e.target.value)}
            placeholder={``}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCustomPromptDialog}>Cancel</Button>
          <Button onClick={handleSaveCustomPrompt}>{isEditing ? 'Save Changes' : 'Save'}</Button>
        </DialogActions>
      </Dialog>
	  </AccordionDetails>
	  </Accordion>
    </div>
  );
};

export default CustomPromptManager;
