import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Chip, TextField, Autocomplete, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TagSelector = ({ tagOptions, selectedTags, setSelectedTags,expanded,titleHidden }) => {
  return (
    <Accordion         {...(expanded && { expanded: true })}
	sx={{backgroundColor:'rgba(0,0,0,0)' ,backdropFilter: 'blur(10px)',
            '-webkit-backdrop-filter': 'blur(10px)'}}>
      {/* The header that the user clicks to expand/collapse */}
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{color:'white'}}/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
		sx={{backgroundColor:'rgba(0,0,0,0)'}}
      >
        <Typography sx={{color:'white '}}>{(!titleHidden?"Tags *":"")}</Typography>
      </AccordionSummary>
      
      {/* The content that is shown when the accordion is expanded */}
      <AccordionDetails sx={{backgroundColor:'rgba(0,0,0,0)'}}>
        <Autocomplete
          multiple
          id="tags-autocomplete"
          options={tagOptions}
          value={selectedTags}
          onChange={(event, newValue) => {
            if (newValue.length <= 20) {
              setSelectedTags(newValue);
            }
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                sx={{
				  backgroundColor:'rgba(0,0,0,0)',
                  color: 'white', // Text color
                  borderColor: 'white', // Border color
                  '& .MuiChip-label': {
                    color: 'white', // Ensure the label text is white
					
                  },
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                input: { color: 'white' }, // Change the input text color to white
                label: { color: 'white' }, // Change the label text color to white
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Change the border color to white
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Change the border color to white when hovered
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white', // Change the border color to white when focused
                  },
                },
              }}
              variant="outlined"
              label="Select Tags (1-20 tags)"
              placeholder="Add Tags"
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default TagSelector;
