import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Custom styles for the drawer content
const DrawerContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, #140326, #2a042c, #000000)',
  color: 'white',
  textAlign: 'left',
  borderRadius: '12px',
}));

// Custom styles for the title
const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.75rem',
  marginBottom: theme.spacing(2),
  color: '#FFEB3B', // A more vibrant color for the title
}));

// Custom styles for the paragraphs
const Paragraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: '1rem',
  lineHeight: '1.75em',
  color: '#E0E0E0', // Softer text color for better readability
  '& strong': {
    fontWeight: 'bold',
    color: '#FFEB3B', // Matching with the title for emphasis
  },
  letterSpacing: '0.5px', // Slight letter spacing for readability
}));

export default function AboutUsDrawer({elementRef}) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };
  const scrollToBottom = () => {
	  console.log(elementRef)
       if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const drawerContent = (
    <DrawerContent>
      <Box
        sx={{
          backgroundColor: 'rgba(0,0,0,0.6)', // More transparency for a smoother look
          borderRadius: '12px',
          padding: 3,
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', // Added shadow for better depth
          overflow: 'hidden',
        }}
      >
        <Title variant="h5">Our Story</Title>
        <Paragraph variant="body1">
          Our journey began in 2020, amidst the global pandemic, when the world slowed down and gave us the chance to explore freelancing online. I started on Upwork, eagerly refreshing the page, searching for opportunities that seemed like a good fit. But this process often took <strong>hours</strong>, sometimes even <strong>days</strong>, and left me feeling like I had wasted a lot of valuable time.
        </Paragraph>
        <Paragraph variant="body1">
          Even when a suitable job post appeared, I quickly realized that copying and pasting a template proposal wasn’t enough to catch a client’s attention. I needed to truly understand the client's problem, present myself authentically, and explain why I was the perfect person for the job.
        </Paragraph>
        <Paragraph variant="body1">
          Two years went by, filled with experimentation and hard work, and I managed to build a relatively stable freelance career. However, it still wasn't close to what I had envisioned. Then it hit me: as an automation expert, why were my daily tasks so repetitive and inefficient?
        </Paragraph>
        <Paragraph variant="body1">
          After extensive experimentation, I realized that an AI could create effective proposals if it had access to my portfolio, the principles of a good proposal, and my templates. This realization led to the creation of <strong>EarlyBird</strong>.
        </Paragraph>
        <Paragraph variant="body1">
          I refined the app and shared it with a few close friends who were also freelancers. Their careers transformed, and I knew I had created something that needed to be shared with others. So, I present to you <strong>EarlyBird</strong>, and I hope you’ll join us on this journey to transform freelancing for the better.
        </Paragraph>
      </Box>
    </DrawerContent>
  );

  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,0)', margin: '10px' }}>
      <AppBar
        position="static"
        sx={{
          background: 'linear-gradient(90deg, rgba(33,150,243,1) 0%, rgba(156,39,176,1) 100%)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
          borderRadius: '12px',
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          {/* About Us */}
          <Typography
            variant="h6"
            component="div"
            sx={{ cursor: 'pointer', flexGrow: 1, fontWeight: 'bold' }}
            onClick={toggleDrawer(true)}
          >
            About Us
          </Typography>

          {/* Join */}
          <Typography
            variant="h6"
            component="div"
            sx={{
              cursor: 'pointer',
              fontWeight: 'bold',
			  ml:4,
              '&:hover': {
                color: '#FFEB3B', // Hover effect for interaction
                transition: 'color 0.3s ease',
              },
            }}
            onClick={scrollToBottom}
          >
            Join
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: { xs: '95%', sm: '70%', md: '50%' },
            backgroundColor: 'rgba(0,0,0,0)',
            borderRadius: '12px',
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </div>
  );
}
