// SubscriptionPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Button, Box, Typography } from '@mui/material';

const SubscriptionPage = ({ currentUser,userID,serverUrl, customerId,infoLoaded }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [portalUrl, setPortalUrl] = useState('');

  useEffect(() => {
    if (!customerId && infoLoaded) {
		console.log("redirect");
      navigate('/payment');
      return;
    }

    const fetchCustomerPortalUrl = async () => {
      const returnUrl = `${window.location.origin}/new-projects`;
      try {


const response = await currentUser.getIdToken(true).then(async function(idToken) {
  // Send token to your backend via HTTPS
  // Corrected fetch call
  return await fetch(`${serverUrl}/payment_create-customer-portal-session?idToken=${idToken}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ customerId, returnUrl })
  });
});

		
 
  
  


        if (!response.ok) {
          throw new Error('Failed to fetch the customer portal URL');
        }

        const data = await response.json();
        if (data.url) {
          setPortalUrl(data.url);
          setLoading(false);
        } else {
          throw new Error('No URL returned from server');
        }
      } catch (error) {
        console.error('Error fetching customer portal URL:', error);
		if (infoLoaded) {
			console.log("go to error");
        navigate('/error'); // Navigate to an error page or handle error differently
		}
      }
    };

    fetchCustomerPortalUrl();
  }, [customerId, navigate, serverUrl]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Manage Your Subscription</Typography>
      <Button variant="contained" onClick={() => window.location.href = portalUrl}>
        Manage My Subscription
      </Button>
    </Box>
  );
};

export default SubscriptionPage;
