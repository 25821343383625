import React, { useState } from 'react';


const TelegramDisconnectButton = ({userID}) => {
		const [clicked,setClicked]=useState(false);

  const handleAddToTelegram = async () => {
    const TelegramOAuthUrl = `https://us-central1-upwork-projects-415513.cloudfunctions.net/telegram-chatbot/cancel?localid=${userID}`;
    setClicked(true);
	window.location.href = TelegramOAuthUrl;

   // window.open(TelegramOAuthUrl, '_blank');
  };

  return (
  <div>
  {!clicked && 
    <button
      style={{
        alignItems: 'center',
        color: '#fff',
        backgroundColor: '#4A154B',
        border: 0,
        borderRadius: '56px',
        display: 'inline-flex',
        fontWeight: 600,
        justifyContent: 'center',
        textDecoration: 'none',
        width: '100%',
        cursor: 'pointer',
		whiteSpace:'nowrap'
      }}
      onClick={handleAddToTelegram}
    >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: '24px', width: '24px', marginRight: '12px' }}
            viewBox="0 0 240 240"
          >
            <path
              d="M120,0C53.725,0,0,53.725,0,120s53.725,120,120,120s120-53.725,120-120S186.275,0,120,0z"
              fill="#0088cc"
            />
            <path
              d="M182.946,68.655l-22.918,108.364c-1.729,7.862-6.265,9.831-12.68,6.123l-35.079-25.885l-16.902,16.252 c-1.867,1.867-3.432,3.432-7.033,3.432l2.52-35.433l64.436-58.114c2.8-2.52-0.607-3.936-4.354-1.415L84.061,124.106l-28.669-8.946 c-7.806-2.438-7.862-7.806,1.626-11.518l110.533-42.676C174.522,57.35,184.673,61.062,182.946,68.655z"
              fill="#FFFFFF"
            />
          </svg>
      Disconnect Telegram
    </button>
  }
  </div>
  );
};

export default TelegramDisconnectButton;
