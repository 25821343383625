import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const Slideshow = ({ slides, interval = 3000, width = '100%', height = 'auto' }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => clearInterval(timer);
  }, [slides, interval]);

  return (
    <Box sx={{ position: 'relative', width, height }}>
      <Box
        component="img"
        src={slides[currentIndex].src}
        alt={slides[currentIndex].title}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: 1,
          boxSizing: 'border-box',
          textAlign: 'left',
        }}
      >
        <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
          {slides[currentIndex].title}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
          {slides[currentIndex].description}
        </Typography>
      </Box>
    </Box>
  );
};

export default Slideshow;
